import { useEffect } from "react";

type RequestCallbackFormProps = { show: boolean };

let hubspotScript;

const RequestCallbackForm = ({ show }: RequestCallbackFormProps) => {
  const openForm = () => {
    window["hbspt"].forms.create({
      portalId: process.env.HUBSPOT_REQUEST_CALLBACK_PORTAL_ID,
      formId: process.env.HUBSPOT_REQUEST_CALLBACK_FORM_ID,
      target: "#requestCallbackForm",
    });
  };

  useEffect(() => {
    if (!hubspotScript) {
      hubspotScript = document.createElement("script");
      hubspotScript.src = "https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(hubspotScript);

      hubspotScript.addEventListener("load", () => {
        openForm();
      });
    } else {
      openForm();
    }
  }, [show]);

  return (
    <div>
      <div id="requestCallbackForm" />
      <p className="text-sm text-gray-500 text-center">
        By submitting these details, I agree to be contacted by SMS, Email or Phone.
      </p>
    </div>
  );
};

export default RequestCallbackForm;
