import clsx from "clsx";
import { Fragment, ReactNode } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import styles from "./index.module.css";

type ModalProps = {
  contentClassName?: string;
  closeButtonClassName?: string;
  title?: ReactNode;
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  scheme?: "light" | "dark";
};

const Modal = ({
  title,
  children,
  isOpen,
  onClose,
  contentClassName,
  closeButtonClassName = "top-4 right-4",
  scheme = "light",
}: ModalProps) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className={clsx("relative z-50", styles[`scheme-${scheme}`])} onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-75" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <button className={clsx(styles.closeButton, closeButtonClassName)} onClick={() => onClose()}>
          <span className="sr-only">Close modal</span>
          <XIcon className={styles.closeButtonIcon} aria-hidden="true" />
        </button>
        <div className="flex min-h-full items-center justify-center md:p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className={clsx(
                "w-full transform overflow-hidden relative align-middle transition-all",
                contentClassName
              )}
            >
              {title && (
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  {title}
                </Dialog.Title>
              )}

              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export default Modal;
