import React from "react";

import clsx from "clsx";
import Button from "components/button";
import DiscoverEnerfloButtonWithOverlay from "components/discover-enerflo-button-with-overlay";
import Heading from "components/heading";
import HeroSection, { HeroSectionContainer } from "components/hero-section";
import { sendAnalyticEvent } from "components/layout/page/use-page";
import RequestCallbackButton from "components/request-callback/button-with-overlay";

import styles from "./index.module.css";

export enum DemoSchemes {
  DARK = "dark",
  LIGHT = "light",
  NAVY = "navy",
}

const newToEnerfloTitle = "New To Enerflo?";
const alreadyUsingEnerfloTitle = "Already Using Enerflo?";

type Props = {
  scheme?: DemoSchemes;
};

const DemoFormSection = ({ scheme = DemoSchemes.DARK }: Props) => {
  return (
    <div className={clsx("py-4", styles[`scheme-${scheme}`])}>
      <div className="w-full mx-auto px-6 xl:px-14">
        <HeroSectionContainer title={newToEnerfloTitle} className={clsx(styles.sectionContainer, "flex-col")}>
          <HeroSection.Inner className="flex-col">
            <div className="flex-grow px-6 w-full sm:px-10 text-center md:mb-8 md:text-center lg:px-14">
              <HeroSection.Header className={clsx(styles.header, "md:items-center")}>
                <Heading tag="h2">{newToEnerfloTitle}</Heading>
              </HeroSection.Header>
            </div>

            <div className="flex flex-col text-center space-y-10 md:flex-row md:space-y-0 md:text-left">
              <div className="flex flex-col items-center w-full tracking-wide space-y-4 sm:pl-0 sm:pr-0 md:w-1/2 md:space-y-6 md:pr-10 lg:pr-16 md:pl-0 lg:pl-0">
                <div className="grow space-y-4 md:space-y-6">
                  <Heading tag="h5" className="text-primary">
                    Book a Meeting Now
                  </Heading>
                  <p className={styles.description}>
                    <strong className="font-bold text-white">Book a Discovery Call</strong> with an Enerflo Platform
                    Consultant to find out if Enerflo is a good fit for your business, get pricing info and more.
                  </p>
                </div>
                <DiscoverEnerfloButtonWithOverlay />
              </div>
              <div className="flex flex-col items-center w-full tracking-wide space-y-4 border-t-2 border-primary border-dashed pt-8 md:w-1/2 md:space-y-6 md:border-l-2 md:border-t-0 md:pt-0 md:pl-10 lg:pl-16">
                <div className="grow space-y-4 md:space-y-6">
                  <Heading tag="h5" className="text-primary">
                    Or, Request a Callback
                  </Heading>
                  <p className={styles.description}>
                    <strong className="font-bold text-white">Please provide us with a few details</strong> so we know
                    how best to reach you for a callback from an Enerflo Platform Consultant.
                  </p>
                </div>
                <RequestCallbackButton />
              </div>
            </div>
          </HeroSection.Inner>
        </HeroSectionContainer>
        <HeroSectionContainer title={alreadyUsingEnerfloTitle} className={clsx(styles.sectionContainer, "flex-col")}>
          <HeroSection.Inner className="flex-col border-t-2 border-primary border-dashed pt-8">
            <div className="flex-grow px-6 text-center w-full sm:px-10 md:mb-8 md:text-center lg:px-14">
              <HeroSection.Header className={clsx(styles.header, "md:items-center")}>
                <Heading tag="h2">{alreadyUsingEnerfloTitle}</Heading>
              </HeroSection.Header>
            </div>

            <div className="flex flex-col text-center w-full md:flex-row">
              <div className="flex flex-col items-center w-full tracking-wide space-y-4 mx-auto md:w-1/2 md:space-y-6">
                <div className="grow space-y-4 md:space-y-6">
                  <Heading tag="h5" className="text-primary">
                    Get Help & Support
                  </Heading>
                  <p className={styles.description}>
                    <strong className="font-bold text-white">Contact our incredible Support Team</strong> for help
                    logging into Enerflo, help using Enerflo, or with any other questions or problems.
                  </p>
                </div>
                <Button
                  href="/support"
                  className="btn btn-primary w-full xs:w-auto md:w-full uppercase font-bold"
                  onClick={() => sendAnalyticEvent("Utility Button", "Buttons", "Get Help & Support")}
                >
                  Get Help & Support
                </Button>
              </div>
            </div>
          </HeroSection.Inner>
        </HeroSectionContainer>
      </div>
    </div>
  );
};

export default DemoFormSection;
