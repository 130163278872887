import Heading from "components/heading";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";

import usePage from "../layout/page/use-page";
import Modal from "../modal";
import RequestCallbackForm from "./form";

const RequestCallbackButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const pageDetails = usePage();

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const open = useCallback(
    e => {
      if (window["analytics"] !== undefined && process.env.NODE_ENV === "production") {
        const idPath = `${router.asPath !== "/" ? router.asPath : ""}/#${e.target.id}`;

        window["analytics"].page({
          title: `${pageDetails.title} | Callback Request`,
          url: `${process.env.SITE_URL}${idPath}`,
          path: idPath,
          referrer: "https://enerflo.com",
        });
      }

      onOpen();
    },
    [pageDetails.title, router.asPath]
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        contentClassName="sm:max-w-[95vw] md:max-w-2xl bg-white p-4 md:p-8 lg:px-10 space-y-4 md:space-y-6"
      >
        <div className="text-left space-y-4 md:space-y-6">
          <Heading tag="h5" className="text-primary">
            Request a Callback
          </Heading>
          <p className="font-light">
            <strong className="font-bold">Please complete the form below</strong> and one of our Platform Consultants
            will contact you within 1 business day (usually faster).
          </p>
        </div>
        <RequestCallbackForm show={isOpen} />
      </Modal>
      <button
        className="btn btn-primary w-full xs:w-auto md:w-full uppercase font-bold"
        onClick={open}
        aria-label="Request a Callback"
        id="request-a-callback"
      >
        Request a Callback
      </button>
    </>
  );
};

export default RequestCallbackButton;
