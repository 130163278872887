import clsx from "clsx";
import Head from "next/head";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

import usePage from "./layout/page/use-page";
import Modal from "./modal";

type DiscoverEnerfloProps = { buttonText?: string; buttonClassName?: string };

const defaultButtonClasses = "w-full xs:w-auto md:w-full";

const DiscoverEnerfloButtonWithOverlay = ({
  buttonText = "Book a Discovery Call",
  buttonClassName = defaultButtonClasses,
}: DiscoverEnerfloProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const pageDetails = usePage();
  const [ready, setReady] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const open = useCallback(
    e => {
      if (window["analytics"] !== undefined && process.env.NODE_ENV === "production") {
        const idPath = `${router.asPath !== "/" ? router.asPath : ""}/#${e.target.id}`;

        window["analytics"].page({
          title: `${pageDetails.title} | Discovery Call Request`,
          url: `${process.env.SITE_URL}${idPath}`,
          path: idPath,
          referrer: "https://enerflo.com",
        });
      }

      onOpen();
    },
    [pageDetails.title, router.asPath]
  );

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <>
      <Head>
        {ready && isOpen && (
          <script
            async
            type="text/javascript"
            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
          ></script>
        )}
      </Head>
      <Modal isOpen={isOpen} onClose={onClose} contentClassName="sm:max-w-[95vw] md:max-w-2xl">
        <div
          className="meetings-iframe-container"
          data-src="https://meetings.hubspot.com/mrrhino/discovery-call?embed=true"
        />
      </Modal>
      <button
        className={clsx("btn btn-primary text-sm sm:text-base uppercase font-bold whitespace-nowrap", buttonClassName)}
        onClick={open}
        aria-label={buttonText}
        id="discover-enerflo-button"
      >
        {buttonText}
      </button>
    </>
  );
};

export default DiscoverEnerfloButtonWithOverlay;
